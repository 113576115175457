import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";
import AudioRecorder from "audio-recorder-polyfill";

// Polyfill MediaRecorder for Safari
if (!window.MediaRecorder) {
  window.MediaRecorder = AudioRecorder;
}

export default class extends Controller {
  static targets = [
    "recordingForm",
    "recordingSongId",
    "recordingLongitude",
    "recordingLatitude",
    "recordingsList",
    "canvas",
  ];

  connect() {
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    this.recording = false;
    this.isProcessing = false;
    this.consumer = createConsumer();
    this.chunkSize = 64 * 1024; // 64KB chunks
    this.stream = null;
    this.mediaRecorder = null;

    this.initializeVisualizer();
    this.initializeAudio();
    this.startVisualizer();
  }

  async initializeAudio() {
    if (navigator.mediaDevices) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.setupAudioNodes(this.stream);
      } catch (err) {
        this.handleError(err);
      }
    } else {
      this.handleError();
    }
  }

  setupMediaRecorder() {
    if (!this.stream) {
      return false;
    }

    const options = { mimeType: "audio/mp4" };
    try {
      this.mediaRecorder = new MediaRecorder(this.stream, options);
    } catch (e1) {
      try {
        this.mediaRecorder = new MediaRecorder(this.stream);
      } catch (e2) {
        return false;
      }
    }

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0 && this.subscription) {
        this.sendAudioChunk(event.data);
      }
    };

    this.mediaRecorder.onstop = this.handleStop.bind(this);
    return true;
  }

  async sendAudioChunk(blob) {
    const arrayBuffer = await blob.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < uint8Array.length; i += this.chunkSize) {
      const chunk = uint8Array.slice(i, i + this.chunkSize);
      const base64data = btoa(String.fromCharCode.apply(null, chunk));
      this.subscription.send({
        audio_chunk: base64data,
      });
    }
  }

  handleError(err) {
    const message = err
      ? `Error: ${err}`
      : "Oh no! Your browser cannot access your computer's microphone. Please update your browser.";
    // console.error(message);
  }

  handleStop(event) {
    this.isProcessing = true;

    if (this.subscription) {
      setTimeout(() => {
        this.subscription.send({ end_of_stream: true });
      }, 1500);
    }
  }

  toggleRecording() {
    if (!this.mediaRecorder && !this.setupMediaRecorder()) {
      return;
    }

    if (this.recording) {
      this.mediaRecorder.stop();
      this.recording = false;
      this.canvasTarget.classList.remove("recording");
      this.canvasTarget.classList.add("disabled");
    } else {
      this.initializeActionCable();
      this.mediaRecorder.start(1000);
      this.recording = true;
      this.isProcessing = false;
      this.canvasTarget.classList.remove("disabled");
      this.canvasTarget.classList.add("recording");
    }
    this.startVisualizer();
  }

  initializeActionCable() {
    this.subscription = this.consumer.subscriptions.create(
      {
        channel: "AudioChannel",
        song_id: this.recordingSongIdTarget.value,
        longitude: this.recordingLongitudeTarget.value,
        latitude: this.recordingLatitudeTarget.value,
      },
      {
        connected: () => {
          // console.log("Connected to AudioChannel");
        },
        disconnected: () => {
          // console.log("Disconnected from AudioChannel");
        },
        received: (data) => {
          if (data.status === "completed") {
            if (this.subscription) {
              this.subscription.unsubscribe();
            }
            this.isProcessing = false;
            window.location.href = `/recordings/${data.recording_id}/edit`;
            // this.dispatch("completed", {
            //   detail: { songId: this.recordingSongIdTarget.value },
            // });
          }
        },
      }
    );
  }

  disconnect() {
    this.isVisualizerRunning = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
  }

  initializeVisualizer() {
    this.canvasCtx = this.canvasTarget.getContext("2d");
    this.analyser = this.audioCtx.createAnalyser();
    this.analyser.fftSize = 2048;
    this.canvasTarget.classList.add("disabled");
  }

  setupAudioNodes(stream) {
    const source = this.audioCtx.createMediaStreamSource(stream);
    source.connect(this.analyser);
    this.visualize();
  }

  startVisualizer() {
    if (!this.isVisualizerRunning) {
      this.isVisualizerRunning = true;
      this.visualize();
    }
  }

  async resumeAudioContext() {
    if (this.audioCtx.state === "suspended") {
      await this.audioCtx.resume();
    }
  }

  visualize() {
    const bufferLength = this.analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = async () => {
      if (!this.isVisualizerRunning) return;

      await this.resumeAudioContext();
      requestAnimationFrame(draw);

      const WIDTH = this.canvasTarget.width;
      const HEIGHT = this.canvasTarget.height;
      this.canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

      if (this.isProcessing) {
        // Draw loading animation
        const time = Date.now() / 1000;
        const centerX = WIDTH / 2;
        const centerY = HEIGHT / 2;
        const maxRadius = Math.min(WIDTH, HEIGHT) / 4;

        // Draw multiple circles with phase offsets
        for (let i = 0; i < 3; i++) {
          const phase = (time * 2 + i * 0.7) % 2;
          const radius = maxRadius * (0.5 + 0.5 * Math.sin(phase * Math.PI));
          const alpha = 0.7 - phase * 0.3;

          this.canvasCtx.beginPath();
          this.canvasCtx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
          this.canvasCtx.strokeStyle = `rgba(255, 100, 100, ${alpha})`;
          this.canvasCtx.lineWidth = 2;
          this.canvasCtx.stroke();
        }

        // Add processing text with fade effect
        const textAlpha = 0.5 + 0.5 * Math.sin(time * 4);
        this.canvasCtx.font = "16px Arial";
        this.canvasCtx.fillStyle = `rgba(255, 100, 100, ${textAlpha})`;
        this.canvasCtx.textAlign = "center";
        this.canvasCtx.fillText(
          "Processing Recording...",
          centerX,
          centerY + maxRadius + 30
        );
      } else {
        this.analyser.getByteTimeDomainData(dataArray);

        this.canvasCtx.lineWidth = 2;
        this.canvasCtx.strokeStyle = this.recording
          ? "rgb(255, 100, 100)"
          : "rgb(150, 150, 150)";
        this.canvasCtx.beginPath();

        const sliceWidth = (WIDTH * 1.0) / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0;
          const y = (v * HEIGHT) / 2;

          if (i === 0) {
            this.canvasCtx.moveTo(x, y);
          } else {
            this.canvasCtx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        this.canvasCtx.lineTo(WIDTH, HEIGHT / 2);
        this.canvasCtx.stroke();
      }
    };

    draw();
  }
}
